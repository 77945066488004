a {
    color: #0c2ff1;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
h1,
h2,
h3,
h4 {
    color: $blue;
}
h1 {
    font-size: calc(3.2rem + 0.4vw);
    @media (max-width: 801px) {
        line-height: 1.2;
    }
}
h2 {
    font-size: calc(2.2rem + 0.4vw);
}
h3 {
    font-size: calc(1.8rem + 0.4vw);
}
h4 {
    font-size: calc(1.4rem + 0.3vw);
    color: #000;
}
p {
    font-size: calc(1.4rem + 0.3vw);
}
