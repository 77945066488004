@import 'variables';
@import 'font';
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
}

img {
    max-width: 100%;
}

.topBar {
    background: #d9dee3;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #a4a4a4;
    margin: 0;
    p {
        margin: 5px 0;
        font-size: 1.4rem;
    }
}
.title {
    background: #425c96;
    padding: 10px 0;
    border-bottom: 10px solid #990000;
    h1 {
        margin: 0;
        text-align: center;
        color: #fff;
    }
}
.breadcrumbs {
    border-top: 1px solid #ebecef;
    border-bottom: 1px solid #ebecef;
}
.breadcrumbContainer {
    width: calc(100% - 20px);
    max-width: 700px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 5px 10px;
    color: #5c6270;
    .spacer {
        margin: 0 5px;
    }
    p {
        margin: 0;
        font-size: 1.4rem;
    }
    a {
        color: #5c6270;
    }
}
.titleLink {
    text-decoration: underline;
    font-size: 48px;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    &:hover {
        color: lighten($blue, 12%);
    }
    @media (max-width: 801px) {
        font-size: 36px;
    }
}
.container {
    width: calc(100% - 20px);
    max-width: 700px;
    margin: auto;
}
.underline {
    text-decoration: underline;
}
.center {
    text-align: center;
}
.ctaBlock {
    background: #425c96;
    text-align: center;
    padding: 10px 20px 0 20px;
    width: 100%;
    max-width: 600px;
    margin: auto;
    p {
        color: #fff;
        margin: 0 0 15px 0;
    }
    a:hover {
        text-decoration: none;
    }
    .ctaButton {
        width: calc(100% + 40px);
        margin-left: -20px;
        background: #df7c39;
        padding: 10px 0;
        -webkit-transition: background 0.2s ease-in-out;
        transition: background 0.2s ease-in-out;
        h3 {
            margin: 0;
            color: #fff;
        }
        &:hover {
            background: $blue;
        }
    }
}

footer {
    background: #2f3138;
    padding: 20px 0;
    color: #767676;
}
.footerContainer {
    width: calc(100% - 20px);
    max-width: 700px;
    margin: auto;
    p {
        margin: 0;
        font-size: 1.2rem;
        &.topSpace {
            margin-top: 20px;
        }
    }
}

@import 'buttons';
