.buttonContainer {
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.ageButton {
    background: $blue;
    color: #fff;
    width: 100%;
    height: 60px;
    font-size: 2.2rem;
    font-weight: 600;
    border-radius: 10px;
    margin-bottom: 15px;
    border: 2px solid $blue;
    transition: background 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        background: #1565c0;
    }
}
.buttonLink {
    width: 48%;
    @media (max-width: 801px) {
        width: 100%;
    }
}
